import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AuthenticatedAdminTfeFormsEditLocationAccessController extends Controller {
  @tracked isPopoutVisible = false;

  @action
  openPopout() {
    this.isPopoutVisible = true;
  }
}
