import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedChecksCheckDetailsRoute extends Route {
  @service router;
  @service session;
  @service store;

  model(params) {
    return this.store.findRecord('tribute-pay/v1/check', params.check_id, { reload : true });
  }

  beforeModel() {
    if (!this.session.currentClient.can_view_financial_config_bank_accounts) {
        this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('checkNumber', model.get('number'));
  }
}
