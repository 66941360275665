/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

const Validations = buildValidations({
  'model.check.amount': validator('number', {
    gt: 0.00,
    allowString: true,
    message: reads('model.checkAmountValidationMessage')
  }),
  'model.check.bankAccountId': validator('presence', {
    presence: true
  }),
  'model.check.date': validator('presence', {
      presence: true,
      message: reads('model.checkDateValidationMessage')
  }),
  'model.check.number': [
    validator('presence', {
      presence: true,
      message: reads('model.checkNumberValidationMessage')
    }),
    validator('number', {
    integer: true,
    gt: 0,
    allowString: true,
    message: reads('model.checkNumberValidationMessage')
  })],
  'model.check.payeeName': validator('presence', {
    presence: true,
    disabled: computed.not('model.check.isOneTimePayee')
  }),
  newPayee: validator('presence', {
    presence: true,
    disabled: reads('model.check.isOneTimePayee')
  }),
});

export default class AuthenticatedChecksCheckDetailsEditController extends Controller.extend(
  Validations) {
    @service flashes;
    @service intl;
    @service router;
    @service api;
    @service store;

    @tracked newPayee;
    @tracked dateRange;
    @tracked showValidations = false;
    @tracked showVoidConfirmDilog = false;
    @tracked showPrintConfirmDilog = false;
    @tracked timeout = 2000;
    @tracked isReadyToDownload = false;
    @tracked isPrintingCheck = false;

    get checkDateValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkDate', { dateRange: this.dateRange });
    }
  
    get checkAmountValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkAmount');
    }
  
    get checkNumberValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkNumber');
    }
  
    get check() {
      return this.model.check;
    }

    @action
    addNewPayee(value){
      set(this, 'newPayee', value);
    }

    @action
    updateBankAccount(bankAccountId) {
      set(this.model.check, 'bankAccountId', bankAccountId);
      set(this.model.check, 'date', moment());
      set(this, 'dateRange', 90);
      set(this.model.check, 'number', null);
  
      if (bankAccountId) {
        const bankAccount = this.model.bankAccounts.find(({ id }) => id === bankAccountId);
        if (bankAccount) {
          set(this, 'dateRange', bankAccount.dateRange);
          set(this.model.check, 'number', bankAccount.nextAvailableCheckNumber || bankAccount.startingCheckNumber);
        }
      }
    }

    @action
    updateCheckAmount(amount) {
      set(this.model.check, 'amount', amount);
    }

    @action
    updateCheckDate(checkDate) {
      set(this.model.check, 'date', checkDate ? moment(checkDate) : null);
    }

    @action
    saveContact(){
      return this.newPayee.save();
    } 

    @action
    toggleIsOneTimePayee(value) {
      set(this.model.check, 'isOneTimePayee', value);
      set(this.model.check, 'payeeName', null);
      set(this, 'newPayee', null);
    }

    @action
    saveAsDraft() {
      this.validate().then(({validations})=>{
        if (validations.get('isValid')){
          set(this, 'showValidations', false);
          set(this.model.check, 'payeeId', this.newPayee?.id);
          set(this.model.check, 'payeeName', this.model.check.payeeName || this.newPayee?.name);
          this.model.check.save().then(()=>{
            this.flashes.addSuccess(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.success'));
            this.router.transitionTo('authenticated.checks.index');
          }).catch((error)=>{
            const checkNumberError = error?.errors?.find((err) =>  err.source?.pointer === '/data/attributes/number');
            if (checkNumberError) {
              this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.checkNumberError'));
            } else {
              this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.error'));
            }
            });
        } else {
          set(this, 'showValidations', true);
        }
      });
    }
  
    @action
    voidCheck() {
      this.validate().then(({ validations }) => {
        if (validations.get('isValid')) {
          set(this, 'showValidations', false);
          set(this, 'showVoidConfirmDilog', true);
        }
        else {
          set(this, 'showValidations', true);
        }
      });

    }

    @action
    printCheck() {
      this.validate().then(({ validations }) => {
        if (validations.get('isValid')) {
          set(this, 'showValidations', false);
          set(this, 'showPrintConfirmDilog', true);
        }
        else {
          set(this, 'showValidations', true);
        }
      });

    }
  
    @action
    confirmVoidingCheck() {
      set(this, 'showVoidConfirmDilog', false);
      set(this.model.check, 'payeeId', this.newPayee?.id);
      set(this.model.check, 'payeeName', this.model.check.payeeName || this.newPayee?.name);
      set(this.model.check, 'status', 'voided');
      this.model.check.save().then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.checks.check-details.edit.messages.void.success'));
      }).catch((error) => {
        const checkNumberError = error?.errors?.find((err) => err.source?.pointer === '/data/attributes/number');
        if (checkNumberError) {
          this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.void.checkNumberError'));
        } else {
          this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.void.error'));
        }
        });
    }

    @action
    async confirmPrintingCheck() {
      set(this, 'showPrintConfirmDilog', false);
      set(this, 'isPrintingCheck', true );
      
      try{
        await this.api.json.post(
          `tfe/documents/${this.check.bankAccountId}/generate`,
          {
            body: {
              bank_account_id: this.check.bankAccountId,
              form_data: {
                  bank_account_id: this.check.bankAccountId,
                  check_id: this.check.id,
                  date: this.check.date,
                  is_one_time_payee: this.check.isOneTimePayee,
                  memo: this.check.memo,
                  number: this.check.number,
                  payee_id: this.newPayee?.id,
                  payee_name: this.check.payeeName || this.newPayee?.name  
              }
            }
          }
        );

        await this.store.findRecord('tribute-pay/v1/check', this.check.id,{ reload: true });

        this.checkIfGenerationCompleted();
      }catch(error){
        set(this, 'isPrintingCheck', false );
        
        const checkNumberError = error?.errors?.find((err) => err.source?.pointer === '/data/attributes/number');
          if (checkNumberError) {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.checkNumberError'));
          } else {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
          }
      }
    }

    async checkIfGenerationCompleted() {
      if(this.check.status === 'printed'){
        try {
          const response = await this.store.findRecord('tfe/generatedDocument', this.check.generatedDocumentId, { reload: true });
          
          if (response.status === 'processing' || response.status === 'pending') {
            this.pollForCheck();
          } else {
            set(this, 'isReadyToDownload', true);
          }

        } catch (error) {
          this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
        }
      }
    }
    
    pollForCheck() {
      this.timeout += 500;
      later(this, this.checkIfGenerationCompleted, this.timeout);
    }
}
