import Controller from '@ember/controller';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed, set } from '@ember/object';

export default class AdminUsersEditLocationAccessController extends Controller {
  @service store;
  @service flashes;
  @service intl;

  @tracked organization_id = null;
  @tracked location_name = '';
  @tracked userId;
  @tracked user;
  @tracked isPopoutVisible = false;
  @tracked pages;

  @tracked selectAllChecked = false;
  @tracked isEnabling = false;

  @tracked unassignedLocations = [];

  @tracked popout_location_name = '';
  @tracked temp_popout_location_name = '';
  @tracked popout_organization_id = null;
  @tracked _v2OrganizationsPromise = null;

  queryParams = ['page', 'organization_id', 'location_name'];

  @tracked locationPage = 1;

  @tracked userLocations = [];

  @computed('_v2OrganizationsPromise', 'userId')
  get v2Organizations() {
    this.loadV2Organizations();

    return this._v2OrganizationsPromise;
  }

  @action
  loadV2Organizations() {
    if (!this._v2OrganizationsPromise || this.popout_organization_id === null) {
      set(this, '_v2OrganizationsPromise', this.store.query('v2/organization', {}));
    }
  }

  @computed('locationPage', 'pages')
  get hasLocationsLeft() {
    return this.pages > 0 && this.locationPage !== this.pages;
  }

  get selectAllState() {
    const selectedCount = this.userLocations.length;
    const totalCount = this.unassignedLocations.length;

    if (selectedCount === 0) {
      return false; // Unchecked
    } else if (selectedCount === totalCount) {
      return true; // Checked
    } else {
      return 'indeterminate'; // Some checked
    }
  }

  @action
  toggleSelectAll(event) {
    this.selectAllChecked = event.target.checked;

    if (this.selectAllChecked) {
      set(this, 'userLocations', this.unassignedLocations.toArray());
    } else {
      set(this, 'userLocations', []);
    }
  }

  @action
  removeUserLocation(userLocation) {
    userLocation.destroyRecord().then(() => {
      this.flashes.addSuccess(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.success')
      );
      this.send('refreshModel');
    })
    .catch(() => {
      this.flashes.addError(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.error')
      );
    });
  }

  @action
  setOrganizationId(value) {
    this.organization_id = isNaN(value) ? null : value;
  }

  @action
  setPopoutOrganizationId(value) {
    this.popout_organization_id = isNaN(value) ? null : value;
    set(this, 'locationPage', 1);
    this.selectAllChecked = false;
  }

  @action
  setLocationName(value) {
    debounce(this, this._setLocationName, value, 400);
  }

  @action
  setPopoutLocationName(value) {
    this.temp_popout_location_name = value;

    debounce(this, this._setPopoutLocationName, this.temp_popout_location_name, 300);
  }

  @action
  openPopout() {
    this.isPopoutVisible = true;
  }

  @action
  toggleLocation(locationId) {
    if (this.locationExists(locationId)) {
      set(this, 'userLocations', this.userLocations.filter((loc) => loc.id !== locationId));
    } else {
      set(this, 'userLocations', [...this.userLocations, this.unassignedLocations.find((loc) => loc.id === locationId)]);
    }
  }

  @action
  locationExists(locationId) {
    return this.userLocations.some((loc) => loc.id === locationId);
  }

  @action
  loadMoreLocations() {
    if (this.locationPage < this.pages) {
      set(this, 'locationPage', this.locationPage + 1);
      const page = this.locationPage;
      const userId = this.userId;

      this.store
        .query('v2/location', {
          per_page: 10,
          not_assigned_to_user_id: userId,
          archived: false,
          organization_id: this.popout_organization_id,
          location_name: this.popout_location_name,
          page
        })
        .then(results => {
          if (this.selectAllChecked) {
            this.userLocations.pushObjects(results.content);
          }

          this.unassignedLocations.content.pushObjects(results.content);
        });
    }
  }

  @action
  async enableLocations() {
    this.isEnabling = true;
    if (this.selectAllChecked) {
      await this.loadAllUnassignedLocations();
    }

    const locationPromises = this.userLocations.map(async (location) => {
      const existingUserLocation = (await this.store.query('v2/userLocation', {
        user_id: this.userId,
        location_id: location.id,
        archived: true
      })).firstObject;

      if (existingUserLocation && existingUserLocation.id) {
        existingUserLocation.set('archivedAt', null);
        return existingUserLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      } else {
        const userLocation = this.store.createRecord('v2/userLocation', {
          user: this.user,
          location
        });

        return userLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      }
    });

    await Promise.all(locationPromises);
    this.isEnabling = false;
    this.closePopout();
  }

  async loadAllUnassignedLocations() {
    const results = await this.store.query('v2/location', {
      per_page: 1000,
      archived: false,
      not_assigned_to_user_id: this.userId,
      organization_id: this.popout_organization_id,
      location_name: this.popout_location_name
    });

    const existingIds = new Set(this.unassignedLocations.map(loc => loc.id));
    const newLocations = results.content.filter(loc => !existingIds.has(loc.id));

    set(this, 'userLocations', [...this.userLocations, ...newLocations]);
  }

  @action
  closePopout() {
    this.isPopoutVisible = false;
    set(this, 'userLocations', []);
    set(this, 'popout_organization_id', null);
    set(this, 'popout_location_name', '');
    set(this, 'locationPage', 1);
    set(this, 'selectAllChecked', false);
    this.getUnassignedLocations();
  }

  _setLocationName(value) {
    this.location_name = value;
  }

  _setPopoutLocationName(value) {
    set(this, 'popout_location_name', value);
    set(this, 'selectAllChecked', false);
  }

  // This is called by the route's model hook
  async getUnassignedLocations() {
    try {
      const locations = await this.store.query('v2/location', {
        per_page: 10,
        not_assigned_to_user_id: this.userId,
        archived: false,
        organization_id: this.popout_organization_id,
        location_name: this.popout_location_name
      });

      set(this, 'unassignedLocations', locations);

      set(this, 'pages', locations.meta.pages);
    } catch (error) {
      console.error('Failed to load unassigned locations', error);
    }
  }
}
